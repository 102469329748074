import 'slick-carousel';
import vars from './vars';

if (vars.$heroSlider.length) {
	function getControlsData(slick) {
		let $prevSlide = $('.slick-active').prev().find('.hero__item');
		let $nextSlide = $('.slick-active').next().find('.hero__item');
		let prevColor = $prevSlide.data('color');
		let nextColor = $nextSlide.data('color');
		let prevLabel = $prevSlide.data('label');
		let nextLabel = $nextSlide.data('label');

		slick.$prevArrow
			.removeClass('is-green')
			.removeClass('is-orange')
			.removeClass('is-blue')
			.addClass(prevColor).data('label', prevLabel);
		slick.$nextArrow
			.removeClass('is-green')
			.removeClass('is-orange')
			.removeClass('is-blue')
			.addClass(nextColor).data('label', nextLabel);

		slick.$prevArrow.find('.hero__label').text(prevLabel);
		slick.$nextArrow.find('.hero__label').text(nextLabel);
	}

	vars.$heroSlider.each((index, element) => {
		let $slider = $(element);
		let $controls = $slider.next();

		$slider.on('init', (event, slick) => {
			getControlsData(slick);
		});

		$slider.slick({
			dots: true,
			arrows: true,
			infinite: true,
			variableWidth: false,
			slidesToShow: 1,
			slidesToScroll: 1,
			nextArrow: '<span class="hero__next slick-next"><span class="hero__label"></span><svg><use xlink:href="/images/sprites.svg#next"></use></svg></span>',
			prevArrow: '<span class="hero__prev slick-prev"><span class="hero__label"></span><svg><use xlink:href="/images/sprites.svg#prev"></use></svg></span>',
			appendArrows: $controls,
			speed: 500,
			accessibility: true,
		});

		$slider.on('afterChange', (event, slick) => {
			getControlsData(slick);
		});
	});
}

if (vars.$productSlider.length) {
	vars.$productSlider.each((index, element) => {
		let $slider = $(element);
		let $controls = $slider.next();

		$slider.slick({
			dots: true,
			arrows: true,
			infinite: false,
			variableWidth: false,
			slidesToShow: 1,
			slidesToScroll: 1,
			nextArrow: '<span class="hero__next slick-next"><span class="hero__label"></span><svg><use xlink:href="/images/sprites.svg#next"></use></svg></span>',
			prevArrow: '<span class="hero__prev slick-prev"><span class="hero__label"></span><svg><use xlink:href="/images/sprites.svg#prev"></use></svg></span>',
			appendArrows: $controls,
			autoplay: true,
			autoplaySpeed: 3000,
			speed: 500,
			accessibility: true,
		});
	});
}
