import Parser from 'ninelines-ua-parser';

const parser = new Parser();
let mobile = false;

if (parser.getDevice().type === 'mobile' || parser.getDevice().type === 'tablet' || innerWidth <= 768) {
	mobile = true;
}

export {mobile};

export default {
	$document: $(document),
	$window: $(window),
	$body: $('body'),
	$bodyHtml: $('body, html'),

	$header: $('.js-header'),
	$headerLink: $('.js-header a'),
	$heroSlider: $('.js-hero-slider'),
	$heroControls: $('.js-hero-controls'),
	$productSlider: $('.js-product-slider'),

	$nav: $('.nav'),
	$navItem: $('.nav__item'),

	$hamburger: $('.js-hamburger'),
	$mobileMenu: $('.js-mobile-menu'),
};
