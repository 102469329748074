import vars, {mobile} from './vars';

// HAMBURGER MENU
vars.$hamburger.on('click', () => {
	vars.$hamburger.toggleClass('is-active');
	vars.$mobileMenu.toggleClass('is-open');
});

// Show submenu
vars.$navItem.hover(
	(event) => {
		let $submenu = $(event.currentTarget).find('.submenu');

		if ($submenu.hasClass('is-visually-hidden')) {
			$submenu.removeClass('is-visually-hidden');
		}
	},
	(event) => {
		let $submenu = $(event.currentTarget).find('.submenu');

		if ($submenu) {
			setTimeout(() => {
				$submenu.addClass('is-visually-hidden');
			}, 150);
		}
	}
);

// Main Menu Links with Hash
if (vars.$headerLink.length) {
	vars.$headerLink.on('click', (event) => {
		let link = event.currentTarget;

		if (/#/.test(link.href) && location.pathname === link.pathname) {
			event.preventDefault();

			if (mobile) {
				vars.$hamburger.removeClass('is-active');
				vars.$mobileMenu.removeClass('is-open');
			}

			let url = $(link).attr('href');
			let hash = url.split('#')[1];
			let id = `#${hash}`;
			let top = $(id).offset().top;

			vars.$bodyHtml.animate({scrollTop: top}, 500, 'swing');
		}
	});
}

// Page load - anchor navigation
if (location.hash) {
	vars.$window.on('load', () => {
		let id = location.hash;
		let top = $(id).offset().top;

		vars.$bodyHtml.animate({scrollTop: top}, 500, 'swing');
	});
}
